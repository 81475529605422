











































































































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import useAccommodation from "@/use/accommodation";
import useMisc from "@/use/misc";

export default defineComponent({
  setup(_, { root }) {
    const { roomIcons } = useAccommodation();
    const { goBack } = useMisc({ root });
    const model = reactive({
      name: "",
      englishName: "",
      hotelId: "",
      price: "0.00",
      weekendPrice: "0.00",
      isEnabled: false,
      amount: null,
      roommate: "without roommate",
      discountsIds: [],
      participantGroupId: "",
      icon: "",
    });

    const state = reactive({
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      valid: false,
      items: [],
      unlimitedAmount: false,
      discounts: [],
      participantGroups: [],
      currency: "",
    });

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 409:
          return `${root.$tc(
            "panel.event.modules.accommodationModule.add.error409"
          )}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`room/${root.$route.params.arid}`)
        .then(({ data }) => {
          model.name = data.name;
          model.englishName = data.englishName;
          model.hotelId = data.hotel.id;
          model.isEnabled = data.isEnabled;
          model.price = data.price;
          model.weekendPrice = data.weekendPrice;
          model.amount = data.amount;
          model.roommate = data.roommate;
          model.discountsIds =
            data.discounts.length > 0
              ? data.discounts.map((d: any) => d.id)
              : [];
          model.participantGroupId = data.participantGroup
            ? data.participantGroup.id
            : "";
          state.unlimitedAmount = data.amount === null ? false : true;
          model.icon = data.icon;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const fetchHotels = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/hotel`)
        .then(({ data: { hotels } }) => {
          state.items = hotels;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchHotels);

    const fetchDiscounts = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/discount`)
        .then(({ data: { discounts } }) => {
          state.discounts = discounts
            .filter((el: any) => !el.isGlobal)
            .map((d: any) => ({
              id: d.id,
              name: d.name,
            }));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchDiscounts);

    const fetchParticipantGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.participantGroups = groups.map((g: any) => ({
            id: g.id,
            name: g.name,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.participantGroups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchParticipantGroups);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        englishName: model.englishName,
        hotelId: model.hotelId,
        price: parseFloat(model.price),
        weekendPrice: parseFloat(model.weekendPrice),
        isEnabled: model.isEnabled,
        amount: state.unlimitedAmount ? model.amount : null,
        roommate: model.roommate,
        discountsIds: model.discountsIds || [],
        participantGroupId: model.participantGroupId || undefined,
        icon: model.icon || null,
      };

      state.loading = true;

      axiosInstance
        .put(`room/${root.$route.params.arid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "panel.event.modules.accommodationModule.editRoom.success"
            ),
          });
          setTimeout(() => {
            goBack("panel.event.view.accommodationModule.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return {
      roomIcons,
      model,
      state,
      rules,
      onSubmit,
    };
  },
});
